import axios from "axios";
import * as types from "./mutation_types";

export const retrieve = ({ commit }) => {
  commit(types.ASSISE_SHOW_TOGGLE_LOADING);

  axios
    .get("/api/assise/last/")
    .then((response) => {
      commit(types.ASSISE_SHOW_TOGGLE_LOADING);
      commit(types.ASSISE_SHOW_SET_RETRIEVED, response.data);
    })
    .catch((e) => {
      commit(types.ASSISE_SHOW_TOGGLE_LOADING);
      commit(types.ASSISE_SHOW_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.ASSISE_SHOW_RESET);
};
