import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";

import user from "./store/user";
import domaine from "./store/domaine";
import session from "./store/session";
import formation from "./store/formation";
import fonction_etb from "./store/fonction_etb";
import etp from "./store/etp";
import etb from "./store/etb";
import adhesion from "./store/adhesion";
import inscription from "./store/inscription";
import inscriptionEtb from "./store/inscriptionEtb";
import assise from "./store/assise";

Vue.config.productionTip = false;
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user,
    domaine,
    session,
    formation,
    fonction_etb,
    etp,
    etb,
    adhesion,
    inscription,
    inscriptionEtb,
    assise,
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
