import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/adhesion",
    name: "Adhesion",
    component: () => import("../views/Adhesion"),
  },
  {
    path: "/assise",
    name: "Assise",
    component: () => import("../views/Assise"),
  },
  {
    path: "/offreDomaine/:id",
    name: "OffreDomaine",
    component: () => import("../views/OffreDomaine"),
  },
  {
    path: "/formation/:id",
    name: "Formation",
    component: () => import("../views/Formation"),
  },
  {
    path: "/inscriptions/",
    name: "Inscriptions",
    component: () => import("../views/Inscriptions"),
  },
  {
    path: "/session/:id",
    name: "Session",
    component: () => import("../views/Session"),
  },
  {
    path: "/PartenaireAssise/:uuid",
    name: "PartenaireAssise",
    component: () => import("../views/PartenaireAssise"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
