import * as types from "./mutation_types";

export default {
  [types.TARIF_RESET](state) {
    Object.assign(state, {
      error: "",
      isLoading: false,
      items: null,
    });
  },

  [types.TARIF_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.TARIF_SET_ITEMS](state, items) {
    Object.assign(state, { items });
  },

  [types.TARIF_TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
