import * as types from "./mutation_types";
import axios from "axios";
import { CSRF_TOKEN } from "@/common/csrf_token";

export const reset = ({ commit }) => {
  commit(types.INSCRIPTION_CREATE_RESET);
};

export const create = ({ commit }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.INSCRIPTION_CREATE_SET_ERROR, "");
  commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
  axios
    .post("/api/inscriptions/", values)
    .then((response) => {
      commit(types.INSCRIPTION_CREATE_SET_RETRIEVED, response.data);
      commit(types.INSCRIPTION_CREATE_SET_CREATED, true);
      commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
    })
    .catch((e) => {
      commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
      commit(types.INSCRIPTION_CREATE_SET_ERROR, e.message);
    });
};

export const createCom = ({ commit }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.INSCRIPTION_CREATE_SET_ERROR, "");
  commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
  axios
    .put("/api/inscriptions/com/" + values.id + "/", values.item)
    .then(() => {
      commit(types.INSCRIPTION_CREATE_SET_CREATED, true);
      commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
    })
    .catch((e) => {
      commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
      commit(types.INSCRIPTION_CREATE_SET_ERROR, e.message);
    });
};

export const updateDate = ({ commit, dispatch }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.INSCRIPTION_CREATE_SET_ERROR, "");
  commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
  axios
    .put("/api/inscriptions/postponed/" + values.id + "/")
    .then(() => {
      commit(types.INSCRIPTION_CREATE_SET_CREATED, true);
      commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
      dispatch("inscription/list/getItems", {}, { root: true });
    })
    .catch((e) => {
      commit(types.INSCRIPTION_CREATE_TOGGLE_LOADING);
      commit(types.INSCRIPTION_CREATE_SET_ERROR, e.message);
    });
};
