<template>
  <v-menu :close-on-content-click="true" :offset-y="true" dark>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="user.is_active"
        text
        v-bind="attrs"
        v-on="on"
        class="d-none d-sm-flex"
      >
        {{ user.username.toUpperCase() }}
      </v-btn>
      <v-btn v-else class="d-none d-sm-flex" text href="/accounts/login/">
        {{ $t("menu.user.connexion") }}
      </v-btn>
      <v-btn
        v-if="user.is_active"
        icon
        v-bind="attrs"
        v-on="on"
        class="d-flex d-sm-none"
      >
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
      <v-btn v-else icon class="d-flex d-sm-none" href="/accounts/login/">
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link href="/accounts/logout/">
        <v-list-item-icon>
          <v-icon>mdi-location-exit</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          >{{ user.username.toUpperCase() }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item link to="/inscriptions/">
        <v-list-item-icon>
          <v-icon>mdi-chair-school</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          >{{ $t("menu.user.inscription") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="user.is_assise" link to="/assise/">
        <v-list-item-icon>
          <v-icon>mdi-chair-school</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("menu.user.assise") }} </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Vue_SousMenuUser",

  computed: {
    ...mapGetters({
      user: "user/show/retrieved",
    }),
  },
};
</script>

<style scoped></style>
