import * as types from "./mutation_types";

export default {
  [types.TRANSPORT_UPDATED_SET_UPDATED](state, updated) {
    Object.assign(state, { updated });
  },

  [types.TRANSPORT_UPDATED_TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },

  [types.TRANSPORT_UPDATED_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },
};
