<template>
  <v-menu v-model="sousdomaine" :close-on-click="true" :offset-y="true" dark>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" class="d-none d-sm-flex">
        {{ $t("menu.offre_formation") }}
      </v-btn>
      <v-btn icon v-bind="attrs" v-on="on" class="d-flex d-sm-none">
        <v-icon>mdi-school</v-icon>
      </v-btn>
    </template>
    <v-list flat>
      <v-list-item
        v-for="(domaine, i) in domaines"
        :key="i"
        link
        :to="'/offreDomaine/' + domaine.id + '/'"
      >
        <v-list-item-title v-text="domaine.name" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "Vue_SousMenuOffreFormation",
  data: () => ({
    sousdomaine: null,
  }),
  props: {
    domaines: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
