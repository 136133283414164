<template>
  <v-list dense color="grey lighten-4">
    <v-list-item-group>
      <v-list-item>
        A {{ session.location }} du {{ session.start }} au
        {{ session.end }}</v-list-item
      >
      <v-list-item> Statut : {{ session.status }}</v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "Vue_ShowSession",
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
