<template>
  <v-container>
    <v-toolbar light flat>
      <v-toolbar-title>
        <a :href="$t('csiesr.url')">
          <v-img
            class="d-none d-xs-flex d-sm-none"
            contain
            width="50px"
            height="30px"
            src="../../assets/logo-CSIESR.png"
            :href="$t('csiesr.url')"
          />
          <v-img
            class="d-none d-md-flex"
            contain
            width="200px"
            height="55px"
            src="../../assets/logo-CSIESR.png"
          />
        </a>
      </v-toolbar-title>
      <v-toolbar-title>
        {{ $t("appli.nom") }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn text class="d-none d-sm-flex" :href="$t('csiesr.urlActu')">
          {{ $t("menu.csiesr") }}
        </v-btn>
        <v-btn icon class="d-flex d-sm-none" :href="$t('csiesr.urlActu')">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn text to="/" class="d-none d-sm-flex">
          {{ $t("menu.agenda") }}
        </v-btn>
        <v-btn icon to="/" class="d-flex d-sm-none">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items>
        <SousMenuOffreFormation :domaines="domaines" />
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn text to="/adhesion" class="d-none d-sm-flex">
          {{ $t("menu.adhesion") }}
        </v-btn>
        <v-btn icon to="/adhesion" class="d-flex d-sm-none">
          <v-icon>mdi-handshake-outline</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-if="assise.lieu.trim() !== ''">
        <v-btn text to="/assise" class="d-none d-sm-flex">
          {{ $t("menu.assise") }}
        </v-btn>
        <v-btn icon to="/assise" class="d-flex d-sm-none">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items>
        <SousMenuUser />
      </v-toolbar-items>
    </v-toolbar>
  </v-container>
</template>

<script>
import SousMenuOffreFormation from "@/components/headers/sousMenus/SousMenuOffreFormation";
import SousMenuUser from "@/components/headers/sousMenus/SousMenuUser";
import { mapGetters } from "vuex";

export default {
  name: "Vue_myMenu",
  components: {
    SousMenuOffreFormation,
    SousMenuUser,
  },
  data: () => ({}),
  props: {
    domaines: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      assise: "assise/show/retrieved",
    }),
  },
};
</script>

<style scoped></style>
