import axios from "axios";
import * as types from "./mutation_types";

export const getItems = ({ commit }) => {
  commit(types.TARIF_TOGGLE_LOADING);
  axios
    .get("/api/assise/tarifs")
    .then((response) => {
      commit(types.TARIF_TOGGLE_LOADING);
      commit(types.TARIF_SET_ITEMS, response.data);
    })
    .catch((e) => {
      commit(types.TARIF_TOGGLE_LOADING);
      commit(types.TARIF_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.TARIF_RESET);
};
