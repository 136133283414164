import * as types from "./mutation_types";

export default {
  [types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING](state) {
    Object.assign(state, { error: "", isLoading: !state.isLoading });
  },

  [types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_SET_UPDATED](state, created) {
    Object.assign(state, { created });
  },
};
