import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/es5/locale/fr";
import "../sass/style.sass";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify);

const theme = {
  //
  // color_4: "#f18321",
  // color_app_bar: "#67bbbd",
  // color_navigation_drawer: "#496277",
  // color_sheet: "#ffefe5",
  // color_main: "#FFF",
  // color_footer: "#67bbbd",
  // color_3: "#9ca5ad",
  // color_5: "#ff18c2",
  // color_tab_header: "#FFF",
  // color_tab_sous_header: "#40d5fb",
  // color_tab_tot: "#ffefe5",
  // color_tab: "#FFF",
  // color_text_tabs: "#FFF",
  // color_text: "#062451",
  // color_text_color_app_bar: "#FFF",
  // color_text_color_navigation_drawer: "#FFF"
};

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
