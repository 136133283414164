import axios from "axios";
import * as types from "./mutation_types";

export const getItems = ({ commit }) => {
  commit(types.INSCRIPTION_TOGGLE_LOADING);
  axios
    .get("/api/myinscriptions/")
    .then((response) => {
      commit(types.INSCRIPTION_TOGGLE_LOADING);
      commit(types.INSCRIPTION_SET_ITEMS, response.data);
    })
    .catch((e) => {
      commit(types.INSCRIPTION_TOGGLE_LOADING);
      commit(types.INSCRIPTION_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.INSCRIPTION_RESET);
};
