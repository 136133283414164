import list from "./list";
import show from "./show";

export default {
  namespaced: true,
  modules: {
    list,
    show,
  },
};
