<template>
  <v-container>
    <v-sheet tile height="54" class="d-flex">
      <v-btn icon @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <template v-if="$refs.calendar">
        <h2 class="text-center text-uppercase">{{ $refs.calendar.title }}</h2>
      </template>
      <v-spacer />
      <v-btn icon @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet min-height="500">
      <v-calendar
        ref="calendar"
        :weekdays="weekday"
        type="month"
        v-model="value"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        @change="getEvents"
        :event-more="false"
        event-text-color="black"
        @click:event="showEvent"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="selectedOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <Calendar :session="selectedEvent" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :to="'session/' + selectedEvent.id">
              {{ $t("btn.plus") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </v-container>
</template>

<script>
import Calendar from "@/components/session/Calendar";

export default {
  name: "Vue_Agenda",
  components: {
    Calendar,
  },
  data: () => ({
    type: "month",
    mode: "stack",
    weekday: [1, 2, 3, 4, 5],
    events: [],
    value: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),

  props: {
    sessions: Array,
  },
  methods: {
    getEvents() {
      if (this.sessions !== undefined) {
        this.events = this.sessions.map((item) => {
          return {
            name: item.formation + " (" + item.location + ")",
            start: item.date_start,
            end: item.date_end,
            color: "#6abde3",
            timed: true,
            id: item.id,
            status: item.status,
            type: item.type,
            location: item.location,
          };
        });
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style scoped>
.v-calendar-weekly {
  height: auto;
  display: table;
  table-layout: fixed;
}

.v-calendar-weekly__week {
  display: table-row;
}

.v-calendar-weekly__day {
  display: table-cell;
  width: calc(100% / 7);
}
</style>
