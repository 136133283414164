import create from "./create";
import list from "./list";

export default {
  namespaced: true,
  modules: {
    create,
    list,
  },
};
