<template>
  <div>
    <template v-if="isLoading">
      <Loading />
    </template>
    <template v-else>
      <MySystemBar />
      <v-app>
        <MyMenu v-if="domaines" :domaines="domaines" />
        <v-main>
          <router-view />
        </v-main>
        <myFooter />
      </v-app>
    </template>
  </div>
</template>

<script>
import MyFooter from "@/components/footer/MyFooter";
import MySystemBar from "@/components/headers/MySystemBar";
import MyMenu from "@/components/headers/MyMenu";
import Loading from "@/components/loading/Loading";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    MyFooter,
    MySystemBar,
    MyMenu,
    Loading,
  },
  computed: {
    ...mapGetters({
      userIsLoading: "user/show/isLoading",
      etbIsLoading: "etb/show/isLoading",
      user: "user/show/retrieved",
      domainesIsLoading: "domaine/list/isLoading",
      domaines: "domaine/list/items",
      assise: "assise/show/retrieved",
      assiseIsLoading: "assise/show/isLoading",
    }),
    isLoading() {
      return (
        this.userIsLoading ||
        this.domainesIsLoading ||
        this.etbIsLoading ||
        this.assiseIsLoading
      );
    },
  },
  methods: {
    ...mapActions({
      userRetrieve: "user/show/retrieve",
      getDomaines: "domaine/list/getItems",
      etbRetrieve: "etb/show/retrieve",
      setAssise: "assise/show/retrieve",
    }),
    reset: function () {
      console.log("ICI");
    },
  },
  mounted() {
    this.userRetrieve();
    this.getDomaines();
    this.etbRetrieve();
    this.setAssise();
  },
};
</script>
