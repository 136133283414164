import * as types from "./mutation_types";
import axios from "axios";
import { CSRF_TOKEN } from "@/common/csrf_token";

export const update = ({ commit }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.ASSISE_PARTENAIRE_CALENDRIER_UPDATE_TOGGLE_LOADING);
  axios
    .put("/api/assise/partenaire/calendrier/" + values.id + "/", values.values)
    .then(() => {
      commit(types.ASSISE_PARTENAIRE_CALENDRIER_UPDATE_SET_UPDATED, true);
      commit(types.ASSISE_PARTENAIRE_CALENDRIER_UPDATE_TOGGLE_LOADING);
    })
    .catch(() => {
      commit(types.ASSISE_PARTENAIRE_CALENDRIER_UPDATE_TOGGLE_LOADING);
    });
};
