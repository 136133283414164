<template>
  <v-system-bar dark>
    <v-spacer />
    <v-btn icon href="https://twitter.com/CSIESR">
      <v-icon small>mdi-twitter</v-icon>
    </v-btn>
    <v-btn icon href="https://www.youtube.com/user/CSIESR/playlists">
      <v-icon small>mdi-youtube</v-icon>
    </v-btn>
    <v-btn icon href="https://www.linkedin.com/company/csiesr">
      <v-icon small>mdi-linkedin</v-icon>
    </v-btn>
  </v-system-bar>
</template>

<script>
export default {
  name: "Vue_MySystemBar",
};
</script>
