import * as types from "./mutation_types";
import axios from "axios";
import { CSRF_TOKEN } from "@/common/csrf_token";

export const reset = ({ commit }) => {
  commit(types.ASSISE_CREATE_RESET);
};

export const create = ({ commit }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.ASSISE_CREATE_SET_ERROR, "");
  commit(types.ASSISE_CREATE_TOGGLE_LOADING);
  axios
    .post("/api/assise/", values.values)
    .then((response) => {
      commit(types.ASSISE_CREATE_SET_RETRIEVED, response.data);
      commit(types.ASSISE_CREATE_SET_CREATED, true);
      commit(types.ASSISE_CREATE_TOGGLE_LOADING);
      values.setUser();
    })
    .catch((e) => {
      commit(types.ASSISE_CREATE_TOGGLE_LOADING);
      commit(types.ASSISE_CREATE_SET_ERROR, e.message);
    });
};
