<template>
  <v-toolbar
    flat
    prominent
    src="../../assets/HEADER-site-CSIESR-6.png"
    id="header-toolbar"
    class="font-weight-bold"
  >
    <v-container>
      <v-row dense>
        <v-col class="text-center">
          <span class="font-weight-bold text-md-h2 black--text mt-4">{{
            msg
          }}</span>
          <br />
          <div class="float-right" v-if="bread">
            <v-breadcrumbs large dark :items="bread"></v-breadcrumbs>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
export default {
  name: "Vue_MyHeader",
  props: {
    msg: {
      type: String,
      required: true,
    },
    bread: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped></style>
