import axios from "axios";
import * as types from "./mutation_types";
import { CSRF_TOKEN } from "@/common/csrf_token";

export const setItem = ({ commit }, values) => {
  commit(types.TRANSPORT_UPDATED_TOGGLE_LOADING);
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  axios
    .put("/api/assise/transports/update/" + values.id + "/", values.values)
    .then((response) => {
      commit(types.TRANSPORT_UPDATED_TOGGLE_LOADING);
      commit(types.TRANSPORT_UPDATED_SET_UPDATED, response.data);
    })
    .catch((e) => {
      commit(types.TRANSPORT_UPDATED_TOGGLE_LOADING);
      commit(types.TRANSPORT_UPDATED_SET_ERROR, e.message);
    });
};
