<template>
  <v-footer dark>
    <v-container>
      <div class="row my-footer">
        <div class="col">
          <div class="white--text">
            <h3>Nous contacter</h3>
            <div>
              <p class="font-weight-bold">
                Comité des Services Informatiques Enseignement Supérieur et
                Recherche (C.S.I.E.S.R.)
              </p>
              <p class="font-weight-bold">
                Siège social :<br />
                c/o La Maison des Universités<br />
                103 boulevard Saint-Michel<br />
                75005 PARIS
              </p>
              <p>
                <span class="font-weight-bold">Webmaster :</span>
                communication“at”csiesr.eu
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <h3 class="widget-title">Contacter l’administration</h3>
          <div class="textwidget">
            <p>
              <strong>Secrétariat du CSIESR :</strong><br />
              secretariat“at”csiesr.eu
            </p>
            <p>
              <strong>L’équipe des FORMATIONS :</strong><br />
              formations“at”csiesr.eu
            </p>
            <p>
              <strong>Adhésions, inscriptions et facturations :</strong><br />
              gestion“at”csiesr.eu
            </p>
          </div>
        </div>
        <div class="col">
          <h3 class="widget-title">Déclarations légales</h3>
          <div class="textwidget">
            <p>
              Conformément à l’article 3 du décret du 16 Août 1901 l’association
              intitulée C.S.I.E.S.R. est déclarée sous le n° de dossier
              W751090664 – <strong>Statuts :</strong>
              <a
                href="https://www.csiesr.eu/wp-content/uploads/2018/06/CSIESR-Statuts_en_cours.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >Télécharger le PDF</a
              >.
            </p>
            <p>
              <strong>Politique de confidentialité :</strong>
              <a
                href="https://www.csiesr.eu/wp-content/uploads/2020/06/Declarations-Legales.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >Télécharger le PDF.</a
              >
            </p>
            <p>
              <strong>Fiche fournisseur du CSIESR : </strong><br />
              <a
                href="https://www.csiesr.eu/wp-content/uploads/2018/06/ficheFournisseur-1.pdf"
                >Télécharger le PDF.</a
              >
            </p>
          </div>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Vue_MyFooter",
  data: () => ({
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
  }),
};
</script>

<style scoped></style>
