<template>
  <div>
    <template v-if="isLoading">
      <Loading />
    </template>
    <template v-else>
      <MyHeader :msg="$t('agenda.header')" />
      <v-container>
        <div class="mt-6 mb-6">
          <Agenda :sessions="sessionsItems" />
        </div>
      </v-container>
    </template>
  </div>
</template>

<script>
import Agenda from "@/components/home/Agenda";
import MyHeader from "@/components/headers/MyHeader";
import Loading from "@/components/loading/Loading";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VueHome",
  components: {
    Agenda,
    MyHeader,
    Loading,
  },
  computed: {
    ...mapGetters({
      sessionsIsLoading: "session/list/isLoading",
      sessionsItems: "session/list/items",
    }),
    isLoading() {
      return this.sessionsIsLoading;
    },
  },
  methods: {
    ...mapActions({
      getSessions: "session/list/getItems",
    }),
  },
  mounted() {
    this.getSessions();
  },
};
</script>
