import axios from "axios";
import * as types from "./mutation_types";

export const retrieve = ({ commit }, id) => {
  commit(types.DOMAINE_SHOW_TOGGLE_LOADING);

  axios
    .get("/api/familles/" + id + "/")
    .then((response) => {
      commit(types.DOMAINE_SHOW_TOGGLE_LOADING);
      commit(types.DOMAINE_SHOW_SET_RETRIEVED, response.data);
    })
    .catch((e) => {
      commit(types.DOMAINE_SHOW_TOGGLE_LOADING);
      commit(types.DOMAINE_SHOW_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.DOMAINE_SHOW_RESET);
};
