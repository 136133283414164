import create from "./create";
import list from "./list";
import tarif from "./tarif";
import show from "./show";
import transport from "./transport";
import updateTransport from "./updateTransport";
import partenaire_show from "./partenaire_show";
import partenaire_update_commerciaux from "./partenaire_update_commerciaux";
import partenaire_update_calendrier from "./partenaire_update_calendrier";

export default {
  namespaced: true,
  modules: {
    create,
    tarif,
    transport,
    updateTransport,
    list,
    show,
    partenaire_show,
    partenaire_update_commerciaux,
    partenaire_update_calendrier,
  },
};
